import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { PaystackButton } from "react-paystack";
import { useSelector } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const PaymentModal = ({
  handleOpenPaymentModal,
  setOpenPaymentModal,
  handleVerify,
  amount,
  finalPayment,
  handleFinalPaymentVerification,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpenPaymentModal(false);
  const { user } = useSelector((state) => state.auth);

  const publicKey = "pk_test_333758610a4ecb30c56395e15428cfcbcf296c0c";

  const successFeedback = (response) => {
    //So this checks if its the final payment and handles it accordingly
    const { reference } = response;

    if (finalPayment) {
      handleFinalPaymentVerification(reference);
    } else {
      handleVerify(reference);
    }

    handleClose();
  };
  const Finalamount = amount * 100;
  const fullname = `${user.Firstname} ${user.Lastname}`;
  const phone = `${user.Phone || null}`;
  const email = user.Email;
  const componentProps = {
    email,
    amount: Finalamount,
    metadata: {
      name: fullname,
      phone,
    },
    publicKey,
    text: "Proceed with payment",
    onSuccess: (response) => {
      successFeedback(response);
    },
    onClose: () => alert("Wait! Don't leave :("),
  };
  return (
    <div>
      <Modal
        open={handleOpenPaymentModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: "bold", fontSize: "16px" }}>
            Process Payment
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This will redirect you to pay a fee of N {amount?.toLocaleString()}
          </Typography>

          <Box className="flex items-center justify-between space-x-3 mt-4">
            <Button
              variant="contained"
              size="medium"
              sx={{
                bgcolor: "#000",
                color: "#fff",
                textTransform: "none",
                fontWeight: "bold",
              }}
              onClick={handleClose}>
              Close
            </Button>

            <PaystackButton
              {...componentProps}
              className="bg-blue-700 text-white rounded-md text-[12px]  px-5 py-2.5"
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PaymentModal;
