import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import FindLand from "./pages/FindLand";
import LandOwner from "./pages/LandDetails";
import BuyLandDashboard from "./pages/BuyLandDashboard";
import SellLandDashboard from "./pages/SellLandDashboard";
import EscrowLandDashboard from "./pages/EscrowLandDashboard";
import BuyLandLists from "./pages/BuyLandLists";
import SellLandForm from "./pages/SellLandForm";
import SellPropertyForm from "./pages/SellPropertyForm";
import EscrowLandForm from "./components/buy,sell,escrow/escrowLandForm/EscrowLandForm";
import LandsForEscrow from "./pages/LandsForEscrow";
import LandForSale from "./pages/LandsForSale";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyRegisteredUser from "./pages/VerifyRegisteredUser";
import ProtectedRoute from "./utils/ProtectedRoutes";
import DashboardRealtor from "./pages/DashboardRealtor";
import DashboardRealtorProperties from "./pages/DashboardRealtorProperties";
import DashboardRealtorLands from "./pages/DashboardRealtorLands";
import LandDetails from "./pages/LandDetails";
import PropertyDetails from "./pages/PropertyDetails";
import DashboardPropertyDetails from "./pages/DashboardPropertyDetails";
import EditPropertyForm from "./pages/EditPropertyForm";
import EditLandForm from "./pages/EditLandForm";
import DashboardLandDetails from "./pages/DashboardLandDetails";
import DashboardRealtorArchivedProperties from "./pages/DashboardRealtorArchivedProperties";
import DashboardRealtorArchivedLands from "./pages/DashboardRealtorArchivedLands";
import SearchLand from "./pages/SearchLand";
import DashboardRealtorEscrowProperties from "./pages/DashboardRealtorEscrowProperties";
import DashboardRealtorVerificationProperties from "./pages/DashboardRealtorVerificationProperties";
import DashboardRealtorVerificationLands from "./pages/DashboardRealtorVerificationLands";
import DashboardVerifyDocs from "./pages/DashboardVerifyDocs";
import DashboardInitiateVerify from "./pages/DashboardInitiateVerify";
import VerifyLandForm from "./pages/VerifyLandForm";
import VerifyPropertyForm from "./pages/VerifyPropertyForm";
import ForgotPassword from "./pages/ForgotPassword";
import InfoRegPage from "./pages/InfoRegPage";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <>
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/info-reg" element={<InfoRegPage />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />

          <Route exact path="/findland/:route" element={<FindLand />} />
          <Route exact path="/searchLand" element={<SearchLand />} />
          <Route exact path="/landDetails/:landId" element={<LandDetails />} />
          <Route
            exact
            path="/propertyDetails/:propertyId"
            element={<PropertyDetails />}
          />
          <Route element={<ProtectedRoute />}>
            <Route exact path="/buyLand" element={<BuyLandDashboard />} />
            <Route exact path="/sellLand" element={<SellLandDashboard />} />
            <Route exact path="/dashboard" element={<DashboardRealtor />} />
            <Route
              exact
              path="/dashboard/verify"
              element={<DashboardInitiateVerify />}
            />

            <Route
              exact
              path="/dashboard/properties"
              element={<DashboardRealtorProperties />}
            />
            <Route
              exact
              path="/dashboard/lands"
              element={<DashboardRealtorLands />}
            />
            <Route
              exact
              path="/dashboard/verifydocs/:uniqueId"
              element={<DashboardVerifyDocs />}
            />
            <Route
              exact
              path="/dashboard/archivedProperties"
              element={<DashboardRealtorArchivedProperties />}
            />
            <Route
              exact
              path="/dashboard/escrowedProperties"
              element={<DashboardRealtorEscrowProperties />}
            />
            <Route
              exact
              path="/dashboard/verificationProperties"
              element={<DashboardRealtorVerificationProperties />}
            />
            <Route
              exact
              path="/dashboard/verificationLands"
              element={<DashboardRealtorVerificationLands />}
            />
            <Route
              exact
              path="/dashboard/archivedLands"
              element={<DashboardRealtorArchivedLands />}
            />
            <Route
              exact
              path="/dashboard/properties/propertyDetails/:propertyId"
              element={<DashboardPropertyDetails />}
            />
            <Route
              exact
              path="/dashboard/lands/landDetails/:landId"
              element={<DashboardLandDetails />}
            />
            <Route
              exact
              path="/editProperty/:propertyId"
              element={<EditPropertyForm />}
            />
            <Route exact path="/editLand/:landId" element={<EditLandForm />} />
            <Route
              exact
              path="/sellPropertyForm"
              element={<SellPropertyForm />}
            />
            <Route exact path="/sellLandForm" element={<SellLandForm />} />
            <Route exact path="/verifyLandForm" element={<VerifyLandForm />} />
            <Route
              exact
              path="/verifyPropertyForm"
              element={<VerifyPropertyForm />}
            />
          </Route>
          <Route
            exact
            path="/verify/:userId/:uniqueString"
            element={<VerifyRegisteredUser />}
          />
          <Route exact path="/escrowLand" element={<EscrowLandDashboard />} />
          <Route exact path="/buyLandLists" element={<BuyLandLists />} />

          <Route exact path="/landForSale" element={<LandForSale />} />
          <Route exact path="/escrowLandForm" element={<EscrowLandForm />} />
          <Route exact path="/landForEscrow" element={<LandsForEscrow />} />
          <Route
            exact
            path="/verify/:userId/:uniqueString/dashboard/verifydocs/:uniqueId"
            element={<VerifyRegisteredUser />}
          />
        </Routes>
      </Provider>
    </BrowserRouter>
    <ToastContainer />
  </>
);
