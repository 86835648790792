import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Backdrop,
  Modal,
  Fade,
} from "@mui/material";
import { useFormik } from "formik";
import { UpdateSchema, validateSchema } from "../../utils/Index";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { register, reset, update } from "../../features/auth/authSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpdateProfileForm = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Phone: user?.Phone || "",
      Nin: user?.Nin || "",
      Address: user?.Address || "",
      State: user?.State || "",
      Country: user?.Country || "",
      Profile: "",
      Gender: "Not Required",
    },
    validationSchema: UpdateSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
      dispatch(update(formData));
    },
  });

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          setSubmitting(false);
        },
      });
    }

    if (isSuccess) {
      toast.info(message, {
        onClose: () => {
          setSubmitting(false);
        },
      });
    }

    dispatch(reset());
  }, [isError, isSuccess, message, user]);

  return (
    <Box className="mt-5">
      <div>
        <Button
          variant="contained"
          sx={{
            bgcolor: "#1e40af",
            textTransform: "none",
            fontWeight: "bold",
            marginTop: "3%",
          }}
          className="bg-blue-700"
          size="medium"
          type="submit"
          onClick={handleOpen}>
          Update Profile
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}>
          <Fade in={open}>
            <Box sx={style}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <Box component="div" className="">
                    <Box
                      onSubmit={handleSubmit}
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { width: "100%" },
                        padding: 0,
                        margin: "5% 0%",
                      }}
                      noValidate
                      autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <div>
                            <TextField
                              label="Phone Number"
                              id="Phone"
                              size="small"
                              inputProps={{ style: { fontSize: 12 } }}
                              onChange={handleChange}
                              value={values.Phone}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.Phone && touched.Phone && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.Phone}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <div>
                            <TextField
                              label="Address"
                              id="Address"
                              size="small"
                              inputProps={{ style: { fontSize: 12 } }}
                              onChange={handleChange}
                              value={values.Address}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.Address && touched.Address && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.Address}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <div>
                            <TextField
                              label="NIN"
                              id="Nin"
                              size="small"
                              inputProps={{ style: { fontSize: 12 } }}
                              onChange={handleChange}
                              value={values.Nin}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.Nin && touched.Nin && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.Nin}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <div>
                            <TextField
                              label="State"
                              id="State"
                              size="small"
                              inputProps={{ style: { fontSize: 12 } }}
                              value={values.State}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.State && touched.State && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.State}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <div>
                            <TextField
                              label="Country"
                              id="Country"
                              size="small"
                              inputProps={{ style: { fontSize: 12 } }}
                              value={values.Country}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.Country && touched.Country && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.Country}
                            </Typography>
                          )}
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={12}>
                          <div>
                            <FormControl size="small" className="w-full">
                              <InputLabel id="Gender">Gender</InputLabel>
                              <Select
                                id="Gender"
                                name="Gender"
                                onChange={(e) => {
                                  setFieldValue("Gender", e.target.value);
                                }}
                                value={values.Gender}
                                label="Gender">
                                <MenuItem value="Male" label="Male">
                                  Male
                                </MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          {errors.Gender && touched.Gender && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.Gender}
                            </Typography>
                          )}
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12}>
                          <div>
                            <TextField
                              label=""
                              id="Profile"
                              type="file"
                              onChange={(e) =>
                                setFieldValue(
                                  "Profile",
                                  e.currentTarget.files[0]
                                )
                              }
                              size="small"
                              onBlur={handleBlur}
                              className={
                                errors.Profile && touched.Profile
                                  ? "border-red-100 border-2 w-100"
                                  : "w-100"
                              }
                            />
                          </div>

                          {errors.Profile && touched.Profile && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.Profile}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>

                      {isSubmitting ? (
                        <Button
                          variant="contained"
                          sx={{
                            bgcolor: "#1e40af",
                            textTransform: "none",
                            fontWeight: "bold",
                            marginTop: "3%",
                          }}
                          className="bg-blue-700"
                          size="medium"
                          type="submit">
                          Please wait...
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{
                            bgcolor: "#1e40af",
                            textTransform: "none",
                            fontWeight: "bold",
                            marginTop: "3%",
                          }}
                          className="bg-blue-700 w-full"
                          size="medium"
                          type="submit">
                          Update Profile
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </div>
    </Box>
  );
};

export default UpdateProfileForm;
