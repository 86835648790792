import React from "react";

import DashboardNavbar from "../components/DashboardNavbar";

import { Box, Container, Grid, Typography } from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Link } from "react-router-dom";
import LandOwnerInfo from "../components/LandOwner/LandOwnerInfo";
import Notification from "../components/addons/Notification";

const DashboardInitiateVerify = () => {
  return (
    <>
      <DashboardNavbar />
      <Box component="div" sx={{ height: "90vh" }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <LandOwnerInfo />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                component="div"
                className="sm:p-5 p-5  bg-green-custom"
                sx={{ height: "90vh" }}>
                <Box component="div" className="p-3 mt-5 sm:mt-0  w-full">
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      opacity: "90%",
                    }}>
                    Verify your documents
                  </Typography>
                  <Box className="grid sm:grid-cols-3 grid-cols-2 gap-4">
                    <Link to="/verifyPropertyForm">
                      <div
                        className={`border  rounded-lg h-20  p-3 text-center justify-center items-center flex space-x-2 mt-5 bg-gray-300 border-gray-300`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                          }}>
                          Built Properties
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link>
                    <Link to="/verifyLandForm">
                      <div
                        className={`border  rounded-lg h-20  p-3 text-center justify-center items-center flex space-x-2 mt-5  bg-gray-300 border-gray-300`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                          }}>
                          Landed Properties
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className="sm:block hidden">
              <Box
                component="div"
                className=" rounded-lg p-3 mt-5 "
                sx={{ height: "78vh" }}>
                <div className="flex justify-between items-center">
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      opacity: "90%",
                    }}>
                    Notifications
                  </Typography>
                  <NotificationsActiveIcon sx={{ color: "#22c55e" }} />
                </div>
                {/* <div>
                  <Notification />
                </div> */}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DashboardInitiateVerify;
