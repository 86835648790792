import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import signupImg from "../../assets/signup.svg";
import { register, reset } from "../../features/auth/authSlice";
import { validateSchema } from "../../utils/Index";
const RegisterForm = ({ redirectFrom }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Firstname: "",
      Lastname: "",
      Email: "",
      Password: "",
      ConfirmPassword: "",
    },
    validationSchema: validateSchema,
    onSubmit: (values, actions) => {
      if (redirectFrom) {
        dispatch(register({ ...values, redirectFrom }));
      } else {
        dispatch(register(values));
      }
    },
  });

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          setSubmitting(false);
        },
      });
    }

    if (isSuccess || user) {
      toast.info(message.message, {
        onClose: () => {
          setSubmitting(false);
          navigate("/info-reg");
        },
      });
    }

    dispatch(reset());
  }, [isError, isSuccess, dispatch, message]);

  return (
    <Box>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <Box component="div" className=" sm:p-20 p-3">
              <Typography variant="h5" sx={{ lineHeight: "2.4rem" }}>
                Open an <br />{" "}
                <Typography
                  variant="span"
                  sx={{
                    fontSize: "45px",
                    fontWeight: "bolder",
                    color: "#1d4ed8",
                  }}>
                  Account
                </Typography>
              </Typography>
              <Typography component="paragraph" sx={{ fontSize: "12px" }}>
                Choose Lock.your.Land.{" "}
                <Typography
                  component="span"
                  sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  {" "}
                  Choose Secured Real Estate Management
                </Typography>
              </Typography>
              <Box
                onSubmit={handleSubmit}
                component="form"
                sx={{
                  "& .MuiTextField-root": { width: "100%" },
                  padding: 0,
                  margin: "5% 0%",
                }}
                noValidate
                autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <div>
                      <TextField
                        label="First Name"
                        id="Firstname"
                        size="small"
                        inputProps={{ style: { fontSize: 16 } }}
                        onChange={handleChange}
                        value={values.Firstname}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.Firstname && touched.Firstname && (
                      <Typography sx={{ fontSize: "11px", color: "red" }}>
                        {errors.Firstname}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div>
                      <TextField
                        label="Last Name or Surname"
                        id="Lastname"
                        size="small"
                        inputProps={{ style: { fontSize: 16 } }}
                        onChange={handleChange}
                        value={values.Lastname}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.Lastname && touched.Lastname && (
                      <Typography sx={{ fontSize: "11px", color: "red" }}>
                        {errors.Lastname}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div>
                      <TextField
                        label="Email Address"
                        id="Email"
                        size="small"
                        inputProps={{ style: { fontSize: 16 } }}
                        onChange={handleChange}
                        value={values.Email}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.Email && touched.Email && (
                      <Typography sx={{ fontSize: "11px", color: "red" }}>
                        {errors.Email}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <TextField
                        label="Password"
                        id="Password"
                        type="password"
                        size="small"
                        inputProps={{ style: { fontSize: 16 } }}
                        value={values.Password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.Password && touched.Password && (
                      <Typography sx={{ fontSize: "11px", color: "red" }}>
                        {errors.Password}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <TextField
                        label="Retype Password"
                        id="ConfirmPassword"
                        type="password"
                        size="small"
                        inputProps={{ style: { fontSize: 16 } }}
                        value={values.ConfirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.ConfirmPassword && touched.ConfirmPassword && (
                      <Typography sx={{ fontSize: "11px", color: "red" }}>
                        {errors.ConfirmPassword}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{
                    bgcolor: "#1e40af",
                    textTransform: "none",
                    fontWeight: "bold",
                    marginTop: "3%",
                  }}
                  className="bg-blue-700"
                  size="medium"
                  type="submit">
                  {isSubmitting ? "Please wait..." : "Register"}
                </Button>
              </Box>
              <Typography
                component="paragraph"
                sx={{ fontSize: "12px", fontWeight: "bold" }}>
                Already have an account?
                <Link to="/login">
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#3b82f6",
                    }}>
                    {" "}
                    Login
                  </Typography>
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box component="div" className="mt-28">
              <img
                src={signupImg}
                alt="sign up images"
                width="100%"
                className="sm:block hidden"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default RegisterForm;
