import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkWithHandler } from "../api";
import propertyService from "./propertyService";

const initialState = {
  property: null,
  propertyByUser: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addProperty = createAsyncThunkWithHandler(
  "property/addProperty",
  async (property, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const UserId = thunkAPI.getState().auth.user._id;

    return await propertyService.addProperties(property, token, UserId);
  }
);

export const addInitializePropertyValidation = createAsyncThunkWithHandler(
  "property/addInitializePropertyValidation",
  async (property, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const UserId = thunkAPI.getState().auth.user._id;
    return await propertyService.addInitializePropertyValidation(
      property.propertyData,
      property.reference,
      token,
      UserId
    );
  }
);

export const getAllProperties = createAsyncThunkWithHandler(
  "property/getAllProperties",
  async () => {
    return await propertyService.getAllProperties();
  }
);

export const getPropertiesByUser = createAsyncThunkWithHandler(
  "property/getPropertiesByUser",
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const id = thunkAPI.getState().auth.user._id;
    return await propertyService.getPropertiesByUser(token, id);
  }
);

export const getArchivedPropertiesByUser = createAsyncThunkWithHandler(
  "property/getArchivedPropertiesByUser",
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const id = thunkAPI.getState().auth.user._id;
    return await propertyService.getArchivedPropertiesByUser(token, id);
  }
);

export const getEscrowPropertiesByUser = createAsyncThunkWithHandler(
  "property/getEscrowPropertiesByUser",
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const id = thunkAPI.getState().auth.user._id;
    await propertyService.getEscrowPropertiesByUser(token, id);
  }
);

export const getPropertiesOnVerificationByUser = createAsyncThunkWithHandler(
  "property/getPropertiesOnVerificationByUser",
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const id = thunkAPI.getState().auth.user._id;
    await propertyService.getPropertiesOnVerificationByUser(token, id);
  }
);

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = action.payload;
        state.propertyByUser = null;
        state.message = "Property Added Successfully";
      })
      .addCase(addProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.property = null;
        state.propertyByUser = null;
      })
      .addCase(addInitializePropertyValidation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addInitializePropertyValidation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = action.payload;
        state.propertyByUser = null;
        state.message = "Congratulations you have added a property";
      })
      .addCase(addInitializePropertyValidation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllProperties.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProperties.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = action.payload;
        state.propertyByUser = null;
      })
      .addCase(getAllProperties.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.property = null;
        state.propertyByUser = null;
      })
      .addCase(getPropertiesByUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPropertiesByUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = null;
        state.propertyByUser = action.payload;
      })
      .addCase(getPropertiesByUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.property = null;
        state.propertyByUser = null;
      })
      .addCase(getArchivedPropertiesByUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArchivedPropertiesByUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = null;
        state.propertyByUser = action.payload;
      })
      .addCase(getArchivedPropertiesByUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.property = null;
        state.propertyByUser = null;
      })
      .addCase(getEscrowPropertiesByUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEscrowPropertiesByUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = null;
        state.propertyByUser = action.payload;
      })
      .addCase(getEscrowPropertiesByUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.property = null;
        state.propertyByUser = null;
      })
      .addCase(getPropertiesOnVerificationByUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPropertiesOnVerificationByUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = null;
        state.propertyByUser = action.payload;
      })
      .addCase(getPropertiesOnVerificationByUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.property = null;
        state.propertyByUser = null;
      });
  },
});

export const { reset } = propertySlice.actions;
export default propertySlice.reducer;
