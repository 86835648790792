import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Box,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import LandOwnerInfo from "../components/LandOwner/LandOwnerInfo";
import Notifications from "../components/buy,sell,escrow/Notifications";
import ProgressBar from "@ramonak/react-progress-bar";
import DashboardNavbar from "../components/DashboardNavbar";
import { currencies, measurements } from "../utils/Index";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSingleLand,
  upadteSingleLands,
} from "../features/single_land/landSlice";
const EditLandForm = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [firstForm, setFirstForm] = useState(true);
  const [file, setFile] = useState([]);
  const dispatch = useDispatch();
  const { isError, isSuccess, message } = useSelector((state) => state.land);
  const { landId } = useParams();
  useEffect(() => {
    dispatch(getSingleLand(landId));
  }, []);
  const {
    land,
    isLoading,
    isError: Error,
    isSuccess: Success,
    message: msg,
  } = useSelector((state) => state.singleLand);

  const closeFirstForm = () => {
    setFirstForm(!firstForm);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Address: land ? land?.lands.Address : "",
      Country: land ? land?.lands.Country : "",
      State: land ? land?.lands.State : "",
      Area: land ? land?.lands.Area : "",
      Currency: land ? land?.lands.Currency : "",
      Measurement: land ? land?.lands.Measurement : "",
      Guarantor: land ? land?.lands.Guarantor : "",
      Escrow: land ? land?.lands.Escrow : "",
      Price: land ? land?.lands.Price : "",
      Size: land ? land?.lands.Size : "",

      Images: [],
      Documents: [],
    },

    onSubmit: (values, actions) => {
      // Create a new FormData object
      const formData = new FormData();
      // Add each property value to the form data
      formData.append("Address", values.Address);
      formData.append("Guarantor", values.Guarantor);
      formData.append("Escrow", values.Escrow);
      formData.append("Price", values.Price);
      formData.append("Size", values.Size);
      formData.append("Currency", values.Currency);
      formData.append("Measurement", values.Measurement);
      formData.append("Country", values.Country);
      formData.append("State", values.State);
      formData.append("Area", values.Area);

      // Convert the FileList object to an array
      for (let index = 0; index < values.Images.length; index++) {
        formData.append("Images", values.Images[index]);
      }

      for (let index = 0; index < values.Documents.length; index++) {
        formData.append("Documents", values.Documents[index]);
      }
      dispatch(upadteSingleLands({ formData, landId }));

      if (Error) {
        toast.error(msg, {
          onClose: () => {
            setSubmitting(false);
          },
        });
      }

      if (Success) {
        toast.info("Land updated Successfully", {
          onClose: () => {
            setSubmitting(false);
            navigate(`/dashboard/lands/landDetails/${landId}`);
          },
        });
      }
    },
  });

  const handleImageUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setImages(selectedFiles);
    setFieldValue("Images", event.target.files);
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFieldValue("Documents", event.target.files);
  };

  return (
    <>
      <DashboardNavbar />
      <Box component="div">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <LandOwnerInfo />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                component="div"
                className="sm:p-5 p-5  bg-green-custom h-min-screen ">
                <Box component="div" className="py-3 mt-2 sm:mt-0">
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      opacity: "90%",
                    }}>
                    Dashboard
                  </Typography>
                  <Box
                    onSubmit={handleSubmit}
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { width: "100%" },
                      padding: 0,
                      margin: "0% 0%",
                    }}
                    noValidate
                    autoComplete="off">
                    {firstForm && (
                      <>
                        <Box className="mb-5 mt-5">
                          <ProgressBar
                            completed={60}
                            bgColor="#15803d"
                            height="35px"
                          />
                        </Box>
                        <Box>
                          <Grid container className="" spacing={2}>
                            {/* Country form */}
                            <Grid item xs={12} sm={4} md={4}>
                              <div>
                                <TextField
                                  label="Country"
                                  Placeholder="Nigeria..."
                                  id="Country"
                                  size="small"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Country}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Country && touched.Country && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Country}
                                </Typography>
                              )}
                            </Grid>
                            {/*State form */}
                            <Grid item xs={12} sm={4} md={4}>
                              <div>
                                <TextField
                                  label="State"
                                  Placeholder="Edo state..."
                                  id="State"
                                  size="small"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.State}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.State && touched.State && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.State}
                                </Typography>
                              )}
                            </Grid>
                            {/*Area form */}
                            <Grid item xs={12} sm={4} md={4}>
                              <div>
                                <TextField
                                  label="Area"
                                  Placeholder="Benin..."
                                  id="Area"
                                  size="small"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Area}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Area && touched.Area && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Area}
                                </Typography>
                              )}
                            </Grid>
                            {/*Location form */}
                            <Grid item xs={12} sm={12} md={12}>
                              <div>
                                <TextField
                                  label="Address/Location of Land"
                                  Placeholder="Km 12 benin Agbor Road..."
                                  id="Address"
                                  size="small"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Address}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Address && touched.Address && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Address}
                                </Typography>
                              )}
                            </Grid>
                            {/*currency form */}
                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  sx={{ fontSize: "12px" }}>
                                  Currency
                                </InputLabel>
                                <Select
                                  labelId="Currency"
                                  id="Currency"
                                  label="Currency"
                                  size="small"
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  sx={{ fontSize: "12px" }}
                                  value={values.Currency}
                                  onChange={(e) => {
                                    setFieldValue("Currency", e.target.value);
                                  }}
                                  onBlur={(e) => {
                                    setFieldValue("Currency", e.target.value);
                                  }}>
                                  {currencies.map((currency) => (
                                    <MenuItem
                                      key={currency.name}
                                      value={currency.abbreviation}
                                      sx={{ fontSize: "12px" }}>
                                      {currency.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {errors.Currency && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Currency}
                                </Typography>
                              )}
                            </Grid>
                            {/*Price form */}
                            <Grid item xs={12} sm={6} md={6}>
                              <div>
                                <TextField
                                  label="Price of Land"
                                  id="Price"
                                  size="small"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Price}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Price && touched.Price && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Price}
                                </Typography>
                              )}
                            </Grid>
                            {/*Measurement form */}
                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  sx={{ fontSize: "12px" }}>
                                  Measurement
                                </InputLabel>
                                <Select
                                  labelId="Measurement"
                                  id="Measurement"
                                  label="Measurement"
                                  size="small"
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  sx={{ fontSize: "12px" }}
                                  value={values.Measurement}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "Measurement",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setFieldValue(
                                      "Measurement",
                                      e.target.value
                                    );
                                  }}>
                                  {measurements.map((measurement) => (
                                    <MenuItem
                                      value={measurement}
                                      sx={{ fontSize: "12px" }}>
                                      {measurement}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {errors.Measurement && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Measurement}
                                </Typography>
                              )}
                            </Grid>
                            {/*Size form */}

                            <Grid item xs={12} sm={6} md={6}>
                              <div>
                                <TextField
                                  label="Size of Land"
                                  id="Size"
                                  size="small"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Size}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Size && touched.Size && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Size}
                                </Typography>
                              )}
                            </Grid>

                            {/*Escrow ammount form */}
                            <Grid item xs={12} sm={6} md={6}>
                              <div>
                                <TextField
                                  label="Escrow Ammount"
                                  id="Escrow"
                                  size="small"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Escrow}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Escrow && touched.Escrow && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Escrow}
                                </Typography>
                              )}
                            </Grid>
                            {/*Owner form */}
                            <Grid item xs={12} sm={6} md={6}>
                              <div>
                                <TextField
                                  label="Property Owner's Name"
                                  id="Guarantor"
                                  size="small"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Guarantor}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Guarantor && touched.Guarantor && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Guarantor}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>

                          <Button
                            variant="contained"
                            disabled={
                              !values.Address ||
                              !values.Currency ||
                              !values.Price ||
                              !values.Escrow ||
                              !values.Measurement ||
                              !values.Size ||
                              !values.Guarantor ||
                              !values.GuarantorPhone ||
                              !values.GuarantorAddress
                            }
                            sx={{
                              bgcolor: "#15803d",
                              textTransform: "none",
                              fontWeight: "bold",
                              marginTop: "2%",
                            }}
                            size="medium"
                            onClick={closeFirstForm}>
                            Continue
                          </Button>
                        </Box>
                      </>
                    )}
                    {!firstForm && (
                      <>
                        <Box className="py-5">
                          <ProgressBar
                            completed={90}
                            bgColor="#15803d"
                            height="35px"
                          />
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                              <Box
                                component="div"
                                className="p-5 bg-green-100 mt-5 rounded-md">
                                <Typography
                                  variant="h4"
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    marginBottom: "1%",
                                  }}>
                                  Authentication Process
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "light",
                                  }}>
                                  All photos of building
                                </Typography>

                                {/* <input
                                  id="Images"
                                  type="file"
                                  multiple
                                  style={{ display: "none" }}
                                  accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                                  onChange={handleImageUpload}
                                />

                                <Button
                                  variant="contained"
                                  sx={{
                                    bgcolor: "#f0fdf4",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    marginTop: "5%",
                                    color: "#14532d",
                                  }}
                                  size="small"
                                  disableElevation
                                  onClick={() =>
                                    document.getElementById("Images").click()
                                  }
                                >
                                  Upload Images
                                </Button> */}
                              </Box>
                              {/* {errors.Documents && touched.Documents && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}
                                >
                                  {errors.Documents}
                                </Typography>
                              )} */}
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                              <Box
                                component="div"
                                className="p-5 bg-green-100 mt-5 rounded-md">
                                <Typography
                                  variant="h4"
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    marginBottom: "1%",
                                  }}>
                                  Authentication Process
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "light",
                                  }}>
                                  All legal documents containing C of O,
                                  Government Excission, Deed of Assignment,
                                  Freehold for property to prove property
                                  ownership
                                </Typography>

                                {/* <input
                                  id="Documents"
                                  type="file"
                                  multiple
                                  style={{ display: "none" }}
                                  accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                                  onChange={handleFileUpload}
                                />

                                <Button
                                  variant="contained"
                                  sx={{
                                    bgcolor: "#f0fdf4",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    marginTop: "5%",
                                    color: "#14532d",
                                  }}
                                  size="small"
                                  disableElevation
                                  onClick={() =>
                                    document.getElementById("Documents").click()
                                  }
                                >
                                  Upload Doc
                                </Button> */}
                              </Box>
                              {/* {errors.Documents && touched.Documents && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}
                                >
                                  {errors.Documents}
                                </Typography>
                              )} */}
                            </Grid>
                          </Grid>

                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: "#1e40af",
                              textTransform: "none",
                              fontWeight: "bold",
                              marginTop: "5%",
                              marginRight: "3%",
                            }}
                            size="medium"
                            onClick={closeFirstForm}>
                            Go Back
                          </Button>

                          {isSubmitting ? (
                            <Button
                              variant="contained"
                              sx={{
                                bgcolor: "#15803d",
                                textTransform: "none",
                                fontWeight: "bold",
                                marginTop: "5%",
                              }}
                              type="submit"
                              size="medium">
                              Please wait...
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              sx={{
                                bgcolor: "#15803d",
                                textTransform: "none",
                                fontWeight: "bold",
                                marginTop: "5%",
                              }}
                              type="submit"
                              size="medium">
                              Complete
                            </Button>
                          )}

                          <div className=" w-full  mt-5">
                            <Grid container spacing={2}>
                              {images.map((image) => (
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                  <img
                                    key={image.name}
                                    src={URL.createObjectURL(image)}
                                    alt={image.name}
                                    className="rounded-md"
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </div>

                          <div className="mt-3">
                            {file && (
                              <Typography
                                varaint="subtitle2"
                                sx={{ fontWeight: "bold" }}></Typography>
                            )}
                            {file && (
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: "13px", fontWeight: "light" }}>
                                {file.name}
                              </Typography>
                            )}
                          </div>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Notifications />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default EditLandForm;
