import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardNavbar from "../components/DashboardNavbar";
import LandOwnerInfo from "../components/LandOwner/LandOwnerInfo";
import PaymentModal from "../components/addons/PaymentModal";
import Notifications from "../components/buy,sell,escrow/Notifications";
import {
  addInitializePropertyValidation,
  reset,
} from "../features/property/propertySlice";
import { PropertySchema, currencies, measurements } from "../utils/Index";
import { countries, nigerianStates } from "../utils/data";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const VerifyPropertyForm = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [firstForm, setFirstForm] = useState(true);
  const [propertyData, setPropertyData] = useState();
  const [handleOpenPaymentModal, setOpenPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentReference, setPaymentReference] = useState("");
  const dispatch = useDispatch();
  const { isError, isSuccess, message } = useSelector(
    (state) => state.property
  );
  const closeFirstForm = () => {
    setFirstForm(!firstForm);
  };

  const handleVerify = (reference) => {
    dispatch(addInitializePropertyValidation({ propertyData, reference }));
    setLoading(true);
  };

  const handleSubmitForm = (formData) => {
    setPropertyData(formData);
    setOpenPaymentModal(true);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Address: "",
      Guarantor: "",
      GuarantorAddress: "",
      GuarantorPhone: "",
      Currency: "",
      Country: "",
      State: "",
      Area: "",
      measurement: "",
      Escrow: "",
      Price: "",
      Size: "",
      BedRooms: "",
      RestRooms: "",
      OtherRooms: "",
      Images: [],
      Documents: [],
      SittingRooms: "",
    },
    validationSchema: PropertySchema,
    onSubmit: (values, actions) => {
      // Create a new FormData object
      const formData = new FormData();
      // Add each property value to the form data
      formData.append("Address", values.Address);
      formData.append("Guarantor", values.Guarantor);
      formData.append("GuarantorAddress", values.GuarantorAddress);
      formData.append("GuarantorPhone", values.GuarantorPhone);
      formData.append("Escrow", values.Escrow.replace(/,/g, ""));
      formData.append("Price", values.Price.replace(/,/g, ""));
      formData.append("Size", values.Size);
      formData.append("BedRooms", values.BedRooms);
      formData.append("RestRooms", values.RestRooms);
      formData.append("OtherRooms", values.OtherRooms);
      formData.append("SittingRooms", values.SittingRooms);
      formData.append("Currency", values.Currency);
      formData.append("Measurement", values.Measurement);
      formData.append("Country", values.Country);
      formData.append("State", values.State);
      formData.append("Area", values.Area);
      formData.append("Listing", false);

      // Convert the FileList object to an array
      for (let index = 0; index < values.Images.length; index++) {
        formData.append("Images", values.Images[index]);
      }

      for (let index = 0; index < values.Documents.length; index++) {
        formData.append("Documents", values.Documents[index]);
      }

      handleSubmitForm(formData);
    },
  });

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          setLoading(false);
        },
      });
    }

    if (isSuccess && message == "Congratulations you have added a property") {
      toast.info(message, {
        onClose: () => {
          setLoading(false);
          navigate("/dashboard/verificationProperties");
        },
      });
    }

    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  //Country Select and State Filter
  const [selectedCountry, setSelectedCountry] = useState(values.Country || "");

  const handleCountryChange = (e) => {
    const { value } = e.target;
    setSelectedCountry(value);
    setFieldValue("Country", value);
  };

  //Images Upload
  const handleImageUpload = (event) => {
    const newImages = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...newImages]);
    setFieldValue("Images", [...images, ...newImages]);
  };
  const handleRemoveImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    setFieldValue("Images", updatedImages);
  };

  //Document Upload
  const [files, setFiles] = useState([]);
  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setFieldValue("Documents", [...files, ...newFiles]);
  };
  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    setFieldValue("Documents", updatedFiles);
  };

  //Format Numbers to have commas
  const formatNumber = (value) => {
    // Remove any non-digit characters
    const number = value.replace(/\D/g, "");
    // Format the number with commas
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handlePriceChange = (event, name) => {
    const rawValue = event.target.value;
    const numericValue = rawValue.replace(/[^0-9]/g, "");
    const formattedValue = formatNumber(numericValue);

    setFieldValue(name, formattedValue);
  };
  return (
    <>
      <DashboardNavbar />

      <Box component="div">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <LandOwnerInfo />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                component="div"
                className="sm:p-5 p-5  bg-green-custom h-min-screen ">
                <Box component="div" className="py-3 mt-2 sm:mt-0">
                  <Link to="/dashboard/verify">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        opacity: "90%",
                      }}>
                      Go Back
                    </Typography>
                  </Link>
                  <Box
                    onSubmit={handleSubmit}
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { width: "100%" },
                      padding: 0,
                      margin: "0% 0%",
                    }}
                    noValidate
                    autoComplete="off">
                    {firstForm && (
                      <>
                        <Box className="mb-5 mt-5">
                          <div className="mb-5 mt-5">
                            <div className="bg-white rounded-lg shadow-md p-2 inline-block">
                              <span className="text-sm font-semibold text-gray-700">
                                Page 1/2
                              </span>
                              <p className="text-xs mt-2 text-gray-600">
                                <span className="text-red-500">(*)</span> Please
                                fill up all details on this page before
                                proceeding to the next.
                              </p>
                            </div>
                          </div>
                        </Box>
                        <Box>
                          <Grid container className="" spacing={2}>
                            {/* Country Form */}
                            <Grid item xs={12} sm={4} md={4}>
                              <div>
                                <FormControl fullWidth>
                                  <InputLabel
                                    id="demo-simple-select-label"
                                    sx={{ fontSize: "12px" }}>
                                    Country
                                  </InputLabel>
                                  <Select
                                    label="Country"
                                    id="Country"
                                    size="medium"
                                    className="w-full"
                                    onChange={handleCountryChange}
                                    value={selectedCountry}
                                    onBlur={handleBlur}
                                    inputProps={{ style: { fontSize: 12 } }}>
                                    {countries.map((country) => (
                                      <MenuItem
                                        key={country.name}
                                        value={country.name}>
                                        {country.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                              {errors.Country && touched.Country && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Country}
                                </Typography>
                              )}
                            </Grid>

                            {/* State form */}
                            <Grid item xs={12} sm={4} md={4}>
                              <div>
                                {selectedCountry === "Nigeria" ? (
                                  <Select
                                    label="State"
                                    id="State"
                                    size="medium"
                                    className="w-full"
                                    onChange={(e) => {
                                      setFieldValue("State", e.target.value);
                                    }}
                                    onBlur={(e) => {
                                      setFieldValue("State", e.target.value);
                                    }}
                                    value={values.State}
                                    inputProps={{ style: { fontSize: 12 } }}>
                                    {nigerianStates.map((state) => (
                                      <MenuItem key={state} value={state}>
                                        {state}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                ) : (
                                  <TextField
                                    label="State"
                                    placeholder="Enter state..."
                                    id="State"
                                    size="medium"
                                    onChange={handleChange}
                                    value={values.State}
                                    onBlur={handleBlur}
                                    inputProps={{ style: { fontSize: 12 } }}
                                  />
                                )}
                              </div>
                              {errors.State && touched.State && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.State}
                                </Typography>
                              )}
                            </Grid>
                            {/*Area form */}

                            <Grid item xs={12} sm={4} md={4}>
                              <div>
                                <TextField
                                  label="LGA"
                                  Placeholder="Benin..."
                                  id="Area"
                                  size="medium"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Area}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Area && touched.Area && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Area}
                                </Typography>
                              )}
                            </Grid>
                            {/*Location form */}
                            <Grid item xs={12} sm={12} md={12}>
                              <div>
                                <TextField
                                  label="Address/Location of Building"
                                  Placeholder="Km 12 benin Agbor Road..."
                                  id="Address"
                                  size="medium"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Address}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Address && touched.Address && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Address}
                                </Typography>
                              )}
                            </Grid>
                            {/*currency form */}
                            <Grid item xs={12} sm={4} md={4}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  sx={{ fontSize: "12px" }}>
                                  Currency
                                </InputLabel>
                                <Select
                                  labelId="Currency"
                                  id="Currency"
                                  label="Currency"
                                  size="medium"
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  sx={{ fontSize: "12px" }}
                                  value={values.Currency}
                                  onChange={(e) => {
                                    setFieldValue("Currency", e.target.value);
                                  }}
                                  onBlur={(e) => {
                                    setFieldValue("Currency", e.target.value);
                                  }}>
                                  {currencies.map((currency) => (
                                    <MenuItem
                                      key={currency.name}
                                      value={currency.abbreviation}
                                      sx={{ fontSize: "12px" }}>
                                      {currency.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {errors.Currency && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Currency}
                                </Typography>
                              )}
                            </Grid>
                            {/*Price form */}
                            <Grid item xs={12} sm={4} md={4}>
                              <div>
                                <TextField
                                  label="Price of Building"
                                  id="Price"
                                  size="medium"
                                  onChange={(e) =>
                                    handlePriceChange(e, "Price")
                                  }
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Price}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Price && touched.Price && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Price}
                                </Typography>
                              )}
                            </Grid>
                            {/*Escrow ammount form */}
                            <Grid item xs={12} sm={4} md={4}>
                              <div>
                                <TextField
                                  label="Escrow Ammount"
                                  id="Escrow"
                                  size="medium"
                                  onChange={(e) =>
                                    handlePriceChange(e, "Escrow")
                                  }
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Escrow}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Escrow && touched.Escrow && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Escrow}
                                </Typography>
                              )}
                            </Grid>
                            {/*Measurement form */}
                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  sx={{ fontSize: "12px" }}>
                                  Measurement
                                </InputLabel>
                                <Select
                                  labelId="Measurement"
                                  id="Measurement"
                                  label="Measurement"
                                  size="medium"
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  sx={{ fontSize: "12px" }}
                                  value={values.Measurement}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "Measurement",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setFieldValue(
                                      "Measurement",
                                      e.target.value
                                    );
                                  }}>
                                  {measurements.map((measurement) => (
                                    <MenuItem
                                      value={measurement}
                                      sx={{ fontSize: "12px" }}>
                                      {measurement}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {errors.measurement && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Measurement}
                                </Typography>
                              )}
                            </Grid>
                            {/*Size form */}

                            <Grid item xs={12} sm={6} md={6}>
                              <div>
                                <TextField
                                  label="Size of Building"
                                  id="Size"
                                  size="medium"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Size}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Size && touched.Size && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Size}
                                </Typography>
                              )}
                            </Grid>
                            {/*siiting rooms form */}
                            <Grid item xs={12} sm={3} md={3}>
                              <div>
                                <TextField
                                  label="Sitting rooms"
                                  id="SittingRooms"
                                  size="medium"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.SittingRooms}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.SittingRooms && touched.SittingRooms && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.SittingRooms}
                                </Typography>
                              )}
                            </Grid>
                            {/*Bedrooms form */}
                            <Grid item xs={12} sm={3} md={3}>
                              <div>
                                <TextField
                                  label="Bed Rooms"
                                  id="BedRooms"
                                  size="medium"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.BedRooms}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.BedRooms && touched.BedRooms && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.BedRooms}
                                </Typography>
                              )}
                            </Grid>
                            {/*rest rooms form */}
                            <Grid item xs={12} sm={3} md={3}>
                              <div>
                                <TextField
                                  label="Rest rooms"
                                  id="RestRooms"
                                  size="medium"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.RestRooms}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.RestRooms && touched.RestRooms && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.RestRooms}
                                </Typography>
                              )}
                            </Grid>
                            {/*Other rooms form */}
                            <Grid item xs={12} sm={3} md={3}>
                              <div>
                                <TextField
                                  label="Other rooms"
                                  id="OtherRooms"
                                  size="medium"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.OtherRooms}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.OtherRooms && touched.OtherRooms && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.OtherRooms}
                                </Typography>
                              )}
                            </Grid>

                            {/*Owner Name */}
                            <Grid item xs={12} sm={6} md={6}>
                              <div>
                                <TextField
                                  label="Property Owner's Name"
                                  id="Guarantor"
                                  size="medium"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.Guarantor}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.Guarantor && touched.Guarantor && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Guarantor}
                                </Typography>
                              )}
                            </Grid>
                            {/*Owner Phone */}
                            <Grid item xs={12} sm={6} md={6}>
                              <div>
                                <TextField
                                  label="Property Owner's Phone Number"
                                  id="GuarantorPhone"
                                  size="medium"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.GuarantorPhone}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.GuarantorPhone &&
                                touched.GuarantorPhone && (
                                  <Typography
                                    sx={{ fontSize: "10px", color: "red" }}>
                                    {errors.GuarantorPhone}
                                  </Typography>
                                )}
                            </Grid>
                            {/*Owner Address */}
                            <Grid item xs={12} sm={12} md={12}>
                              <div>
                                <TextField
                                  label="Property Owner's Address"
                                  id="GuarantorAddress"
                                  size="medium"
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.GuarantorAddress}
                                  onBlur={handleBlur}
                                  inputProps={{ style: { fontSize: 12 } }}
                                />
                              </div>
                              {errors.GuarantorAddress &&
                                touched.GuarantorAddress && (
                                  <Typography
                                    sx={{ fontSize: "10px", color: "red" }}>
                                    {errors.GuarantorAddress}
                                  </Typography>
                                )}
                            </Grid>
                          </Grid>

                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: "#15803d",
                              textTransform: "none",
                              fontWeight: "bold",
                              marginTop: "2%",
                            }}
                            size="medium"
                            onClick={closeFirstForm}>
                            Continue
                          </Button>
                        </Box>
                      </>
                    )}
                    {!firstForm && (
                      <>
                        <Box className="py-5">
                          <div className="mb-5 mt-5">
                            <div className="bg-white rounded-lg shadow-md p-2 inline-block">
                              <span className="text-sm font-semibold text-gray-700">
                                Page 2/2
                              </span>
                              <p className="text-xs mt-2 text-gray-600">
                                <span className="text-red-500">(*)</span> Please
                                fill up all details on this page before
                                proceeding to submit.
                              </p>
                            </div>
                          </div>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Box
                                component="div"
                                className="p-5 bg-green-100 mt-5 rounded-md">
                                <Typography
                                  variant="h4"
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    marginBottom: "1%",
                                  }}>
                                  Authentication Process
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "light",
                                  }}>
                                  Upload all photos of building
                                </Typography>

                                <input
                                  id="Images"
                                  type="file"
                                  multiple
                                  style={{ display: "none" }}
                                  accept=".jpg,.jpeg,.png"
                                  onChange={handleImageUpload}
                                />

                                <Button
                                  variant="contained"
                                  sx={{
                                    bgcolor: "#f0fdf4",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    marginTop: "5%",
                                    color: "#14532d",
                                  }}
                                  size="medium"
                                  disableElevation
                                  onClick={() =>
                                    document.getElementById("Images").click()
                                  }>
                                  Upload Images
                                </Button>
                              </Box>
                              {errors.Documents && touched.Documents && (
                                <Typography
                                  sx={{ fontSize: "10px", color: "red" }}>
                                  {errors.Documents}
                                </Typography>
                              )}
                              <div className="grid mt-5 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                                {images.map((image, index) => (
                                  <div key={index} className="relative">
                                    <img
                                      src={URL.createObjectURL(image)}
                                      alt={`Preview ${index}`}
                                      className="w-full h-40 object-cover rounded-lg"
                                    />
                                    <button
                                      onClick={() => handleRemoveImage(index)}
                                      className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600">
                                      <Close size={10} />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                              <Box
                                component="div"
                                sx={{
                                  p: 3,
                                  bgcolor: "green.100",
                                  mt: 3,
                                  borderRadius: 2,
                                  border: "1px solid",
                                  borderColor: "green.300",
                                }}>
                                <Typography
                                  variant="h5"
                                  sx={{
                                    fontWeight: "bold",
                                    mb: 2,
                                    color: "green.800",
                                  }}>
                                  Authentication Process
                                </Typography>

                                <Typography
                                  variant="body1"
                                  sx={{ mb: 2, color: "green.900" }}>
                                  Please upload the following legal documents to
                                  prove property ownership:
                                </Typography>

                                <List dense>
                                  {[
                                    "Certificate of Occupancy (C of O)",
                                    "Government Excision",
                                    "Deed of Assignment",
                                    "Freehold documentation",
                                  ].map((item, index) => (
                                    <ListItem key={index} disableGutters>
                                      <ListItemIcon>
                                        <CheckCircleOutlineIcon color="success" />
                                      </ListItemIcon>
                                      <ListItemText primary={item} />
                                    </ListItem>
                                  ))}
                                </List>

                                <Typography
                                  variant="body2"
                                  sx={{
                                    mt: 2,
                                    fontWeight: "medium",
                                    color: "green.800",
                                  }}>
                                  Fees:
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: "green.700" }}>
                                  • Document processing: NGN 30,000 per document
                                  search on a) CAC Search b) Probate Registory
                                  and c) Land Registory
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: "green.700" }}>
                                  • Initial survey deposit: NGN 50,000
                                </Typography>

                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    mt: 2,
                                    fontWeight: "bold",
                                    color: "green.900",
                                  }}>
                                  Total initial payment: NGN 140,000
                                </Typography>

                                <Typography
                                  variant="body2"
                                  sx={{
                                    mt: 2,
                                    fontStyle: "italic",
                                    color: "green.800",
                                  }}>
                                  You'll be redirected to our secure payment
                                  portal to complete the transaction.
                                </Typography>

                                <input
                                  id="Documents"
                                  type="file"
                                  multiple
                                  style={{ display: "none" }}
                                  accept=".jpg,.jpeg,.png"
                                  onChange={handleFileUpload}
                                />

                                <Button
                                  variant="contained"
                                  sx={{
                                    bgcolor: "#f0fdf4",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    marginTop: "5%",
                                    color: "#14532d",
                                  }}
                                  size="medium"
                                  disableElevation
                                  onClick={() =>
                                    document.getElementById("Documents").click()
                                  }>
                                  Upload Documents
                                </Button>
                              </Box>
                              {errors.Documents && touched.Documents && (
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    color: "error.main",
                                    mt: 1,
                                  }}>
                                  {errors.Documents}
                                </Typography>
                              )}

                              <div className="grid mt-5 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                                {files.map((file, index) => (
                                  <div key={index} className="relative">
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={`Preview ${index}`}
                                      className="w-full h-40 object-cover rounded-lg"
                                    />
                                    <button
                                      onClick={() => handleRemoveFile(index)}
                                      className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600">
                                      <Close size={10} />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </Grid>
                          </Grid>

                          <Box className="flex space-x-2 mt-5">
                            <Button
                              variant="contained"
                              disabled={
                                images?.length === 0 ||
                                files.length === 0 ||
                                isSubmitting
                              }
                              sx={{
                                bgcolor: "#1e40af",
                                textTransform: "none",
                                fontWeight: "bold",

                                marginRight: "3%",
                              }}
                              size="medium"
                              onClick={closeFirstForm}>
                              Go Back
                            </Button>

                            <Button
                              variant="contained"
                              disabled={
                                loading ||
                                files.length === 0 ||
                                images.length === 0
                              }
                              sx={{
                                bgcolor: "#15803d",
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                              type="submit"
                              size="medium">
                              {loading ? "Please wait..." : "Complete"}
                            </Button>
                            <PaymentModal
                              handleOpenPaymentModal={handleOpenPaymentModal}
                              setOpenPaymentModal={setOpenPaymentModal}
                              handleVerify={handleVerify}
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Notifications />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default VerifyPropertyForm;
